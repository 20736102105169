<template>
	<div class="row h-100">
		<div class="col-12 col-md-6 mx-auto my-auto text-center">
			<div class="card auth-card">
				<div class="form-side">
					<router-link tag="a" to="/">
						<img class="logo logo-full-width org-logo" height="75" width="129" />
					</router-link>
					<form @submit.prevent="formSubmit">
						<div v-if="!tokenReceived">
							<h3 class="mb-4">{{ $t('user.forgot-password')}}</h3>
							<div class="row">
								<div class="col-md-4">
									<span class="float-left p-1">{{ $t('user.email') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="email" name="email" class="form-control" v-model="email">
									</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<span class="float-left p-1">{{ $t('user.username') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="text" name="username" class="form-control" v-model="username">
									</label>
								</div>
							</div>
						</div>
						<div v-if="tokenReceived && token">
							<h3 class="mb-4">{{ $t('user.reset-password')}}</h3>
							
							<ul class="text-left">
								<li>{{ $t('user.password-length') }}</li>
								<li>{{ $t('user.password-letter') }}</li>
								<li>{{ $t('user.password-number') }}</li>
							</ul>
							
							<div class="row">
								<div class="col-md-4 text-left">
									<span>{{ $t('user.email') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="email" name="email" class="form-control" v-model="email">
									</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 text-left">
									<span>{{ $t('user.username') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="text" name="username" class="form-control" v-model="username">
									</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 text-left">
									<span>{{ $t('user.password') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="password" name="password" autocomplete="new-password" class="form-control" v-model="password">
									</label>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4 text-left">
									<span>{{ $t('user.password_confirm') }}</span>
								</div>
								<div class="col-md-8">
									<label class="form-group has-float-label mb-4">
										<input type="password" name="password-confirm" autocomplete="new-password" class="form-control" v-model="password_confirm">
									</label>
								</div>
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center" v-if="!tokenReceived || token">
							<router-link tag="a" to="/page/user/login">{{ $t('user.back-to-login')}}</router-link>
							<button type="submit" class="btn btn-primary btn-lg btn-shadow btn-square" :disabled="password != password_confirm">{{ $t('user.reset-password-button')}}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
	data () {
		return {
			email: '',
			password: '',
			password_confirm: '',
			token: false,
			tokenReceived: false
		}
	},
	
	methods: {
		...mapActions(['recoverPassword']),
		formSubmit () {
			if (!this.email && !this.token) {
				this.$swal(this.$t('warning.label'), this.$t('messages.user.recovery.required'), "warning");
				return;
			}
			this.recoverPassword({ email: this.email, username: this.username, token: this.token, password: this.password, password_confirm: this.password_confirm }).then((response) => {
				if (!this.tokenReceived) {
					this.tokenReceived = true;
					if (!this.token) {
						this.$router.push('/page/user/login');
					}
				} else {
					this.$router.push('/page/user/login');
				}
			}, (error) => {
				// swal made in recover password
				
			});
		},
		
		checkToken: function () {
			if (this.$route.query.recovery) {
				this.token = this.$route.query.recovery;
				this.tokenReceived = true;
			}
		}
	},
	
	mounted () {
		this.checkToken();
	}
}
</script>
